const user_data = [
    {
        "name": "अमर सिंह",
        "post": "MD",
        "company": "Satta King Office",
        "description": "आज की सिंगल जोड़ी गली दिसावर धमाका",
        "mobile": "9193943714",
        "contact": ["WhatsApp", "Call Now", "Satta Play App Download"]
    },
    {
        "name": "RUBEY SEXSENA",
        "post": "Ceo Of Satta Company",
        "description": "सिंगल जोड़ी फीस एडवांस जमा करवानी होगी फरीदाबाद गाजियाबाद गली दिशावर में कंपनी से लीक जोड़ी जो भी भाई अपना लॉस कवर करना चाहते हो गेम सिंगल जोड़ी में ही मिलेगा गेम किसी का बाप काट नहीं सकता अपनी बुकिंग करने के लिए आज ही व्हाट्सएप और कॉल करे",
        "mobile": "9523695744",
        "contact": ["WhatsApp", "Call Now"]
    },
    {
        "name": "Dilip Singh Rana",
        "post": "सट्टे में पैसा ही कमाना तो LEAK गेम लेना ही पड़ेगा",
        "description": "फरीदाबाद,गाजियाबाद,गली,दिशावर में मिलेगी ऑनली सिंगल जोड़ी जो होगी 1001% गारंटी से पास. हमारे साथ काम करने वाले भाईयों का हर महीने लाखों का प्रॉफिट होता हैं। आप भी ज्वाइन कीजिए। गेम लेने के लिए अभी व्हाट्सएप करें",
        "mobile": "6307500417",
        "contact": ["WhatsApp", "Call Now"]
    },
    {
        "name": "Blank",
        "post": "101% गेम पास की गारंटी",
        "description": "अगर आपका नुकसान है आपके घर में कोई परेशानी है जैसे कर्जा है या किसी की शादी है या कोई बीमार है या घर में गरीबी है तो तुरंत संपर्क करें, टाइम खराब करने वाले लोग मैसेज न करें, सट्टा किंग डेट फिक्स जोड़ी 1001% कन्फर्म और सिंगल जोड़ी मिलेगी लेकिन बिना एंट्री के कोई गेम नहीं मिलेगा आफ्टर पास डेमो वाले लोग मार्केट में धक्के खाते रहेंगे मुझे जरूरत नहीं है मेरे पास वही लोग मैसेज करेंगे जिनको मुझ पर भरोसा है कि राधे भाई हमें सट्टा किंग लीक गेम दे सकते हैं राधे-राधे जय हरे कृष्णा",
        "mobile": "2222222222",
        "contact": ["WhatsApp", "Call Now"]
    }
]
export default user_data ;
