import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons"; // Import the phone icon
import user_data from "./data"
const AdvertisementComponent = ({ type }) => {
  const [oddAdd, setOddAdd] = useState([]);
  const [evenAdd, setEvenAdd] = useState([]);
  const [randomAdd, setRandomAdd] = useState([]);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/advertisementUpdate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log('advertisement json', json);
        const odd = [];
        const even = [];
        const random = [];
        json.slice(27, 30).forEach((item, index) => {
          if (index % 3 === 0) {
            odd.push(item); // Every 3rd item goes into odd
          } else if (index % 3 === 1) {
            even.push(item); // Every 3rd + 1 item goes into even
          } else {
            random.push(item); 
          }
        });
        setOddAdd(odd);
        setEvenAdd(even);
        setRandomAdd(random);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // generate whatsapp massanger
  const generateWhatsAppLink = (mobile, name) => {
    const message = `Hello ${name}, I am interested in your advertisement.`;
    return `https://api.whatsapp.com/send?phone=${mobile}&text=${encodeURIComponent(
      message
    )}`;
  };

  // generate call dialer
  const generateCallLink = (mobile) => {
    return `tel:${mobile}`; 
  };
  const renderAds = (ads) => {
    return ads.map((ad, index) => (
      <div key={index} className="col-md-12 ad-section bg-dark text-white p-3 rounded shadow">
        <div className="col-lg-12">
          <h3 className="bg-success text-white p-2 rounded">Name: {ad?.name}</h3>
          <h6
            className="desc text-white"
            dangerouslySetInnerHTML={{
              __html: ad?.description,
            }}
          />
          <h4 className="text-white">Mobile: {ad?.mobile}</h4>
          <h4 className="text-white">Post: {ad?.post}</h4>
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {/* WhatsApp Button */}
                <a
                  href={generateWhatsAppLink(ad.mobile, ad.name)}
                  target="_blank"
                  className="btn btn-success form-control m-2 col-md-6 col-sm-12"
                >
                  <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp Now
                </a>
              </div>
              <div className="col-md-6 col-sm-12">
                {/* Call Button */}
                <a
                  href={generateCallLink(ad.mobile)}
                  className="btn btn-outline-light form-control m-2 col-md-6 col-sm-12"
                >
                  <FontAwesomeIcon icon={faPhone} /> Call Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="advertisementSection">
      <div className="row">
        {type==="odd"&&oddAdd.length > 0 && (
          <>
            <h2 className="col-12 text-center">Odd Ads</h2>
            {renderAds(oddAdd)}
          </>
        )}
      </div>
      <div className="row">
        {type==="even"&&evenAdd.length > 0 && (
          <>
            <h2 className="col-12 text-center">Even Ads</h2>
            {renderAds(evenAdd)}
          </>
        )}
      </div>
      <div className="row">
        {type==="random"&&randomAdd.length > 0 && (
          <>
            <h2 className="col-12 text-center">Random Ads</h2>
            {renderAds(randomAdd)}
          </>
        )}
      </div>
    </div>
  );
};


export default AdvertisementComponent;
