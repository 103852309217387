import "./App.css";
import moment from "moment";
import "moment-timezone";
import React, { useState, useEffect } from "react";
import Banner from "./banner";
import Disclaimer from "./js/disclaimer";
import FAQ from "./js/faq";
import ContactUs from "./js/contact";
import PrivacyPolicy from "./js/privacy";
import AboutUs from "./js/about";
import { useLocation } from "react-router-dom";
import logo from "./images/satta-king-logo.png";
import trackVisitor from "./utilities/tracker";
import DaySattaResult from "./js/daySattaResult";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
const momenttz = require("moment-timezone");

function App({ sectiondata }) {
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  const location = useLocation();
  const isDisc = location.pathname.includes("/disclaimer");
  const isContact = location.pathname.includes("/contact");
  const isPrivacy = location.pathname.includes("/privacypolicy");
  const isAbout = location.pathname.includes("/about");
  const isFaq = location.pathname.includes("/faq");
  // const currentTime = moment().format("HH:mm");
  const todayDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  const [currentTime, setCurrentTime] = useState(moment().tz("Asia/Kolkata").format("HH:mm:ss"));
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().tz("Asia/Kolkata").format("HH:mm:ss"));
    }, 1000); // Update every second
  
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);
  

  var prevDate = moment(new Date())
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  // const [gamedata, setGameData] = useState([]);
  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm");
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]);

  // track all site visited data

  useEffect(() => {
    trackVisitor(); // Call the tracking function when the component mounts
  }, []);

  function pageScroll() {
    const tableSection = document.getElementById("monthTable");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div className="App">
      {/* seo setup start */}
      <Helmet>
        <title></title>
        <meta
          name="description"
          content="This is the page for getting all details."
        />
        <meta
          name="keywords"
          content="sattaking, satta king,satta-king-online, bgm satta, satta king, sattaking, satta result, satta, satta king online, satta king, games, numbergames, number games, game results"
        />
        <link rel="canonical" href="https://satta-king-online.co/" />
      </Helmet>
      {/* seo setup end */}{" "}
      <div className="App bg-dark">
        <nav className="navbar navbar-expand-lg navbar-light themecolor">
          <div className="container-fluid ">
            <a className="navbar-brand" href="/">
              <img
                src={logo}
                alt="Logo"
                width="120"
                height="50"
                className="d-inline-block align-top"
              />
            </a>
            <div className="d-flex ms-auto">
              <a href="/">
                <button className="btn btn-danger me-2" type="button">
                  HOME🏠
                </button>
              </a>
              <a onClick={pageScroll}>
                <button className="btn btn-danger me-2" type="button">
                  RESULT💹
                </button>
              </a>
              <a className="btn btn-danger" href="/contact" alt="Satta king">
                CONTACT📞
              </a>
              {/* <a href="/contact">
                <button className="btn btn-danger" type="button">
                  CONTACT
                </button>
              </a> */}
            </div>
          </div>
        </nav>
        <marquee className="text-white">
          Satta king, Sattaking, Satta king, Satta result, Satta king result,
          Satta king live, Satta king online , Desawar result, Gali result,
          Faridabad result, Gaziyabad result, Satta king chart, Desawar record
          chart, Gali record chart, Faridabad record chart, Gaziyabad record
          chart
        </marquee>
        <h1 className="text-center text-white fw-bold themecolor">
          👑SATTA King👑
        </h1>
      </div>
      {/* <Banner /> */}
      <div className="banner text-white p-3 sattaReal">
        {/* <h5 className="">{todayDate}</h5> */}
        <p className="text-center" style={{fontSize:'24px',color:'yellowgreen'}}>🕗Current Time: {currentTime}</p> {/* Display running current time */}
        <p className="text-center"  style={{fontSize:'24px',color:'yellowgreen'}}> {currentDate}</p> {/* Display current date */}
        
        {datagame.map((game, index) => (
          <div key={index} className="game mb-4">
            <h3>{game.gameName}</h3>
            <h5 className=" blinking-text">{game?.result || "--"}</h5>
          </div>
        ))}
      </div>
      <AdvertisementComponent type="odd" />
      <DaySattaResult dayGameData={data} />
      {isDisc && <Disclaimer style={{ display: "none" }} />}
      {isContact && <ContactUs style={{ display: "none" }} />}
      {isPrivacy && <PrivacyPolicy style={{ display: "none" }} />}
      {isAbout && <AboutUs style={{ display: "none" }} />}
      {isFaq && <FAQ style={{ display: "none" }} />}
    </div>
  );
}

export default App;
